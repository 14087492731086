import * as Yup from 'yup'
import { useMemo } from 'react'
import debounce from 'lodash.debounce'
import { FormikHelpers } from 'formik'
import { useLazyQuery } from '@apollo/client'
import formatTaxNumber from 'lib/format-tax-number'

import { useToast } from 'components/Toast'
import { useMutation } from 'hooks/useMutation'
import joinErrorPaths from 'lib/error-handling/join-error-paths'
import CHECK_SELLER_SLUG from './graphql/CheckSellerSlug.graphql'
import { CheckSellerSlug, CheckSellerSlugVariables } from './graphql/__generated__/CheckSellerSlug'
import CREATE_SELLER_ACCOUNT_MUTATION from './graphql/CreateSellerAccountMutation.graphql'
import UPDATE_SELLER_ACCOUNT_MUTATION from './graphql/UpdateSellerAccountMutation.graphql'
import {
  CreateSellerAccountMutation,
  CreateSellerAccountMutationVariables,
  CreateSellerAccountMutation_createSeller_seller
} from './graphql/__generated__/CreateSellerAccountMutation'
import {
  AddressAttributesArguments,
  SellerAttributesArguments,
  TaxNumberCheckStatusEnum
} from '../../../../../__generated__/globalTypes'
import {
  UpdateSellerAccountMutation,
  UpdateSellerAccountMutationVariables
} from './graphql/__generated__/UpdateSellerAccountMutation'
import { CheckAccountDetails_accountVerification } from '../SearchForBusinessNumberForm/graphql/__generated__/CheckAccountDetails'
import { priorityCountryOptions } from 'lib/countries'

type UseCreateSellerFormProps = {
  onSuccess: (seller: CreateSellerAccountMutation_createSeller_seller) => void
  businessData: CheckAccountDetails_accountVerification
  initialFieldValues?: CreateSellerAccountMutation_createSeller_seller
  defaultCountry?: string
}

type Country = ReturnType<typeof priorityCountryOptions>[number]['value']

type CreateSellerAccountArguments = {
  phone: string
  country: Country
} & SellerAttributesArguments

const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/
const instagramRegex = /^@?[\w](?!.*?\.{2})[\w.]{1,28}[\w]$/
const twitterRegex = /^@?[a-zA-Z0-9_]{1,15}$/
const facebookRegex = /(?:https?:\/\/)?(?:www\.)?facebook\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/

export const socialMediaDetailsAttributesValidation = Yup.object().shape({
  id: Yup.string().optional(),
  websiteUrl: Yup.string()
    .nullable()
    .test('missing-https', "must be a valid URL, starting with 'https://'", value => {
      return !Boolean(value != null && urlRegex.test(value) && !/^https?:\/\//i.test(value))
    })
    .url()
    .max(191),
  instagramUsername: Yup.string()
    .test(
      'not-url',
      'Enter your Instagram username, not a URL',
      value =>
        !(
          value != null &&
          Boolean(value) &&
          !(instagramRegex.test(value) && !/instagram\.com/i.test(value)) &&
          (urlRegex.test(value) || /instagram\.com/i.test(value))
        )
    )
    .matches(instagramRegex, 'Not a valid Instagram username')
    .nullable()
    .max(191),
  twitterUsername: Yup.string()
    .test(
      'not-url',
      'Enter your Twitter username, not a URL',
      value =>
        !(
          value != null &&
          Boolean(value) &&
          !(twitterRegex.test(value) && !/twitter\.com/i.test(value)) &&
          (urlRegex.test(value) || /twitter\.com/i.test(value))
        )
    )
    .matches(twitterRegex, 'Not a valid Twitter username')
    .nullable()
    .max(191),
  facebookUrl: Yup.string()
    .matches(facebookRegex, 'must be a valid facebook URL, e.g. https://www.facebook.com/my-page')
    .nullable()
    .max(191)
})

const useCreateSellerForm = ({
  onSuccess,
  businessData,
  initialFieldValues,
  defaultCountry
}: UseCreateSellerFormProps) => {
  const [showToast] = useToast()
  const [isSlugAvailable] = useLazyQuery<CheckSellerSlug, CheckSellerSlugVariables>(CHECK_SELLER_SLUG)

  // async debounced function, for use with Yup.
  // Note that it returns a resolved promise, which is what Yup is expecting
  const checkSlug = debounce(
    async (
      resolve: (value: boolean | Yup.ValidationError | PromiseLike<boolean | Yup.ValidationError>) => void,
      slug?: string
    ) => {
      if (slug) {
        const result = await isSlugAvailable({ variables: { slug } })
        resolve(result?.data?.isSellerSlugAvailable ?? true)
      } else {
        resolve(true)
      }
    },
    300
  )

  const addressAttributeValidation = useMemo(() => {
    return Yup.object().shape({
      id: Yup.string().optional(),
      street1: Yup.string().max(191).optional(),
      street2: Yup.string().max(191).optional().nullable(),
      city: Yup.string().max(191).required(),
      state: Yup.string().max(191).required(),
      country: Yup.string().max(191).required(),
      postcode: Yup.string().max(191).optional().nullable()
    })
  }, [])

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      taxNumber: Yup.string().required('Required'),
      entityName: Yup.string().required('Required'),
      displayName: Yup.string().required('Required'),
      slug: Yup.string()
        .required()
        .max(191)
        .test('availability', 'is not available', slug => new Promise(resolve => checkSlug(resolve, slug))),
      phone: Yup.string().required('Required'),
      accountAddressAttributes: addressAttributeValidation,
      socialMediaDetailsAttributes: socialMediaDetailsAttributesValidation,
      country: Yup.string()
    })
  }, [addressAttributeValidation, checkSlug])

  const initialValues: CreateSellerAccountArguments = {
    taxNumber: formatTaxNumber({
      taxNumber: businessData.taxNumber ?? '',
      country: defaultCountry ?? ''
    }),
    entityName: businessData.businessName ?? '',
    displayName: initialFieldValues?.displayName ?? '',
    slug: initialFieldValues?.slug ?? '',
    accountAddressAttributes: {
      id: initialFieldValues?.accountAddress?.id ?? undefined,
      street1: initialFieldValues?.accountAddress?.street1 ?? '',
      street2: initialFieldValues?.accountAddress?.street2 ?? '',
      city: initialFieldValues?.accountAddress?.city ?? '',
      state: initialFieldValues?.accountAddress?.state ?? '',
      postcode: initialFieldValues?.accountAddress?.postcode ?? '',
      country: initialFieldValues?.accountAddress?.country ?? '',
      phone: initialFieldValues?.accountAddress?.phone ?? ''
    },
    phone: initialFieldValues?.accountAddress?.phone ?? '',
    socialMediaDetailsAttributes: {
      id: initialFieldValues?.socialMediaDetails?.id ?? undefined,
      websiteUrl: initialFieldValues?.socialMediaDetails?.websiteUrl ?? '',
      instagramUsername: initialFieldValues?.socialMediaDetails?.instagramUsername ?? '',
      twitterUsername: initialFieldValues?.socialMediaDetails?.twitterUsername ?? '',
      facebookUrl: initialFieldValues?.socialMediaDetails?.facebookUrl ?? ''
    },
    country: defaultCountry ?? 'AU'
  }

  const [createSeller] = useMutation<CreateSellerAccountMutation, CreateSellerAccountMutationVariables>(
    CREATE_SELLER_ACCOUNT_MUTATION,
    {
      context: { hasUpload: true },
      onCompleted: data => {
        if (data?.createSeller?.success && data.createSeller.seller) {
          onSuccess && onSuccess(data.createSeller.seller)
        }
      },
      onError: () => showToast({ kind: 'error', message: 'Failed to create seller' })
    }
  )

  const [updateSeller] = useMutation<UpdateSellerAccountMutation, UpdateSellerAccountMutationVariables>(
    UPDATE_SELLER_ACCOUNT_MUTATION,
    {
      context: { hasUpload: true },
      onCompleted: data => {
        if (data?.updateSeller?.success && data.updateSeller.seller) {
          onSuccess && onSuccess(data.updateSeller.seller)
        }
      },
      onError: () => showToast({ kind: 'error', message: 'Failed to update seller' })
    }
  )

  const onSubmit = async (
    values: CreateSellerAccountArguments,
    { setSubmitting, setFieldError }: FormikHelpers<CreateSellerAccountArguments>
  ) => {
    setSubmitting(true)

    let result, errors
    const { phone, country, ...rest } = values

    if (initialFieldValues?.id) {
      result = await updateSeller({
        variables: {
          id: initialFieldValues.id,
          attributes: {
            ...rest,
            accountAddressAttributes: {
              ...rest.accountAddressAttributes,
              phone
            } as unknown as AddressAttributesArguments,
            taxNumberCheckStatus: TaxNumberCheckStatusEnum.VERIFIED
          }
        }
      })
      errors = joinErrorPaths(result?.data?.updateSeller?.errors)
    } else {
      result = await createSeller({
        variables: {
          attributes: {
            ...rest,
            accountAddressAttributes: {
              ...rest.accountAddressAttributes,
              phone
            } as unknown as AddressAttributesArguments,
            taxNumberCheckStatus: TaxNumberCheckStatusEnum.VERIFIED
          },
          country
        }
      })
      errors = joinErrorPaths(result?.data?.createSeller?.errors)
    }

    errors?.forEach(e => (e.path && e.message ? setFieldError(e.path, e.message) : ''))
    setSubmitting(false)
  }

  return {
    initialValues,
    validationSchema,
    onSubmit
  }
}

export default useCreateSellerForm
